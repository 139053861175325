export const getId = (
  prefix: string,
  key?: number | string,
): string | undefined => (key === undefined ? undefined : `${prefix}-${key}`);

export const getIds = (
  prefixs: string[],
  key?: number | string,
): string | undefined => {
  if (key === undefined) {
    return undefined;
  }
  return prefixs.map((prefix) => getId(prefix, key)).join(' ');
};

export const getAriaHidden = (key?: number | string): boolean | undefined =>
  key === undefined ? undefined : true;
