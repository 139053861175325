import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import DefaultImageLarge from '../img/lg/default-image.svg';
import DefaultImageSmall from '../img/sm/default-image.svg';

import STYLES from './AlternativeImage.scss';

type Props = {
  className?: string;
  large?: boolean;
  alt?: string;
  width?: number | undefined;
  height?: number | undefined;
};

const cls = cssModules(STYLES);

const AlternativeImage = ({ alt, className, large, ...rest }: Props) => (
  <img
    src={large ? DefaultImageLarge : DefaultImageSmall}
    className={cls('AlternativeImage', className)}
    alt={alt}
    {...rest}
  />
);

AlternativeImage.defaultProps = {
  className: '',
  alt: '',
  large: true,
  width: undefined,
  height: undefined,
};

export default AlternativeImage;
